import React from 'react';

interface AboutProps {
    imgSrc: string;
    imgAlt: string;
    advantages: string[];
}

const About: React.FC<AboutProps> = ({ imgSrc, imgAlt, advantages }) => {
    return (
        <section className="about">
            <div className="container pt-5 pb-5">
                <div className="row gy-4 justify-content-center">
                    <div className="col-lg-5 content"  data-aos="zoom-in">
                        <h2>Ventajas de nuestros servicios</h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <ul>
                                    {advantages.map((advantage, index) => (
                                        <li key={index}>
                                            <i className="material-symbols-outlined">next_week</i> <strong>{advantage}</strong>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <p className="py-3"></p>
                    </div>
                    <div className="col-lg-4 position-relative"  data-aos="zoom-in">
                        <div className="row">
                            <div className="col-12">
                                <img src={imgSrc} className="img-fluid rounded-5" alt={imgAlt} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
