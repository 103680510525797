import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

interface ContactFormProps {
  show: boolean;
  handleClose: () => void;
}

const ModalContactForm: React.FC<ContactFormProps> = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    Nombre: '',
    numero: '',
    correo: '',
    mensaje: 'Estoy interesado en sus servicios, quiero agendar una cita.'
  });

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDanger, setShowAlertDanger] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { Nombre, numero, correo,mensaje } = formData;

    if (Nombre && numero && correo) {
      try {
        const response = await fetch('https://grupoacssi.com/PHPMailer/php/enviarcorre.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
          },
          body: new URLSearchParams({
            ...formData,
            boton_correo: 'enviar_correo',
          }).toString(),
        });

        const result = await response.json();
        console.log(result);
        if (result.respuesta_servi === '1') {

          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 2500);
          setFormData({ Nombre: '', numero: '', correo: '', mensaje: 'Estoy interesado en sus servicios, quiero agendar una cita.' });
        } else {
            setShowAlertDanger(true);
            setTimeout(() => setShowAlertDanger(false), 2500);
        }
      } catch (error) {
        setShowAlertDanger(true);
        setTimeout(() => setShowAlertDanger(false), 2500);
      }
    } else {
        setShowAlertDanger(true);
        setTimeout(() => setShowAlertDanger(false), 2500);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Enviar Correo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlert && <Alert variant="success">¡Correo enviado exitosamente!</Alert>}
        {showAlertDanger && <Alert variant="danger">¡Ocurrio un error al enviar elcorreo, intentalo nuevamente.!</Alert>}
        <Form id="form_Correo" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="Nombre"
              placeholder="Ingrese su nombre completo seguido de su apellido paterno"
              value={formData.Nombre}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="text"
              name="numero"
              placeholder="Ingrese su número de teléfono a 10 dígitos"
              value={formData.numero}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control
              type="email"
              name="correo"
              placeholder="ejemplo@spem.com.mx"
              value={formData.correo}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Mensaje</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="mensaje"
              placeholder="Ingrese el mensaje de tu correo"
              value={formData.mensaje}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="main-button icon-button mt-3">
            Enviar Correo
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="main-button1 btn btn-danger">
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalContactForm;
