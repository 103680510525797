
import React from 'react';

interface GalleryItemProps {
    imgSrc: string;
    imgAlt: string;
    previewLinkTitle: string;
    detailsLink: string;
    handleShowModal: (imgSrc: string, previewLinkTitle: string) => void;
}

const GalleryItem: React.FC<GalleryItemProps> = ({ imgSrc, imgAlt, previewLinkTitle, detailsLink, handleShowModal }) => {
    return (
        <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="gallery-item h-100">
                <img src={imgSrc} className="img-fluid" alt={imgAlt} />
                <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a href="#" title={previewLinkTitle} className="glightbox preview-link" onClick={(e) => { e.preventDefault(); handleShowModal(imgSrc, previewLinkTitle); }}>
                        <i className="material-symbols-outlined">pan_zoom</i>
                    </a>
                    <a href={detailsLink} className="details-link" target='_blank'>
                        <i className="material-symbols-outlined">open_in_new</i>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default GalleryItem;