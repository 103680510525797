import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer id="footer" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="footer-logo">
                            <h4 className='text-white'>GRUPO ACSSI</h4>
                        </div>
                    </div>
                    <div className="col-md-6">

                        <ul className="nav justify-content-end">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#home">Inicio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#mision">Visión</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#services">Servicios</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled" href="#contact" >Contacto</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="bottom-footer" className="row">
                    <div className="col-md-4">
                        <ul className="footer-social">
                            <li><a href="https://www.facebook.com/GRUPO-ACSSI-440503503442308/" className="facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a></li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <div className="footer-copyright lh-1 lh-sm text-white">
                            <span>&copy; Copyright 2019. Elaborado por GRUPO ACSSI.</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
