import { useState } from "react";
import ModalContactForm from "../Boostrap/ModalContactForm";

interface FormData {
  name: string;
  email: string;
}

const Contact: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <section id="contact"  className="mb-5">

      {/* Background Image */}
      <div className="bg-image1 bg-parallax overlay" style={{ backgroundImage: 'url(./img/Picture10.png)' }}></div>
      {/* Background Image */}

      {/* Container */}
      <div className="container">

        {/* Row */}
        <div className="row">

          <div className="col-md-12 text-center"  data-aos="zoom-in">
            <h3 className="text-white pt20 mpt14">Contactanos</h3>
            
              <p className="text-white pt18 mpt13 mb-2"><i className="material-symbols-outlined">location_on</i> Huxquilucan, Edo. MEx.</p>
              <p className="text-white pt18 mpt13 mb-2"><i className="material-symbols-outlined">mail</i> contacto@acssi.com</p>
              <p className="text-white pt18 mpt13 mb-2"><i className="material-symbols-outlined">phone</i> 5563678720 o 5575547656</p>
            

            <button type="button" className="btn btn-outline-primary" onClick={handleShowModal}>Enviar Correo</button>
          </div>
          {/* Contact information */}

        </div>
        {/* /Row */}

      </div>
      {/* /Container */}
      <ModalContactForm show={showModal} handleClose={handleCloseModal} />
    </section>
    
  );
};

export default Contact;
