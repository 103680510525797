import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import index_img from "../assets/img/06.jpg";
import img_sistema from "../assets/img/sistema.jpg";
import img_contact from "../assets/img/contact.png";
import img_11 from "../assets/img/01.jpg";
import img_t1 from "../assets/img/t_1.png";
import img_t2 from "../assets/img/t_2.png";
import img_t3 from "../assets/img/t_3.png";
import img_w1 from "../assets/img/w_1.png";
import img_w2 from "../assets/img/w_2.png";
import img_w3 from "../assets/img/w_3.png";
import img_w4 from "../assets/img/w_4.png";
import Carousel from "../components/Carousel";
import About from "../components/Section/About";
import ServicesSection from "../components/Section/ServicesSection";
import Footer from "../components/Section/Footer";
import Contact from "../components/Section/ContactSection";
import GallerySection from "../components/Section/GallerySection";
import Aos from 'aos';
import 'aos/dist/aos.css';
import ParallaxSection from "../components/ParallaxSection";

const Home: React.FC = () => {
    useEffect(() => {
        Aos.init();
    }, []

    );
    const navigate = useNavigate();
    const softwareServices = [
        {
            icon: 'settings_system_daydream',
            title: 'PAGINAS WEB',
            description: 'Creamos páginas web responsivas, modernas y nos adaptamos a las necesidades de cada uno de nuestros clientes para crear una imagen que fortalezca su identidad en línea.',
            link: '',
        },
        {
            icon: 'terminal',
            title: 'SISTEMAS WEB',
            description: 'Creamos sistemas web a partir de un analisis profundo para dar una solución oportuna y eficaz, segun el problema presentado por cada cliente.',
            link: '',
        },
        {
            icon: 'database',
            title: 'ADMINISTRACIÓN DE BASE DE DATOS',
            description: 'Tienes una base de datos? te ofrecemos la administración, depuración, migración, le damos un tratamiento adecuado para que nuestros clientes puedan usar los datos para la toma de decisiones.',
            link: '',
        },
        {
            icon: 'table_chart',
            title: 'GENERACION DE ESTADISTICAS Y REPORTES',
            description: 'Centralizamos y depuramos tus base de datos para poder brindarte un reporte centralizado y su mayoria automatizado.',
            link: '',
        },
    ];

    const networkServices = [
        {
            icon: 'lan',
            title: 'INSTALACIÓN DE RED',
            description: '',
            link: '',
        },
        {
            icon: 'computer',
            title: 'INSTALACIÓN DE EQUIPOS DE CÒMPUTO',
            description: '',
            link: '',
        },
        {
            icon: 'print',
            title: 'INSTALACIÓN DE IMPRESORAS',
            description: '',
            link: '',
        },
        {
            icon: 'videocam',
            title: 'INSTALACIÓN DE CAMARAS DE VIGILANCIA',
            description: '',
            link: '',
        },
    ];

    const maintenanceServices = [
        {
            icon: 'engineering',
            title: 'MANTENIMIENTO PREVENTIVO',
            description: 'Revisión y optimización del funcionamiento del computador, con el fin de prevenir fallas y mal funcionamiento del equipo, tanto en el software como en el hardware.',
            link: '',
        },
        {
            icon: 'settings_system_daydream',
            title: 'MANTENIMIENTO CORRECTIVO',
            description: 'Este servicio se presta en la eventualidad de una falla puntual del equipo, bien sea de Hardware o Software.',
            link: '',
        },
        {
            icon: 'history',
            title: 'RESPALDO DE SU INFORMACIÓN',
            description: 'Le ofrecemos respaldos de forma automatizadas, mantenga seguro su información sensible, nosotros le ayudamos a formar procesos de respaldos.',
            link: '',
        },
        {
            icon: 'settings_remote',
            title: 'SOPORTE TÉCNICO PRESENCIAL - REMOTO',
            description: 'Damos soporte técnico vía remota a través de línea telefónica, internet o cualquier otro tipo de contacto de larga distancia.',
            link: '',
        },
    ];
    const galleryItems1 = [
        {
            imgSrc: img_t1,
            imgAlt: 'Descripción de la imagen 1',
            previewLinkTitle: 'Summer Sale',
            detailsLink: 'https://carrito.grupoacssi.com/tienda/',
        },
        {
            imgSrc: img_t2,
            imgAlt: 'Descripción de la imagen 2',
            previewLinkTitle: 'Shop',
            detailsLink: 'https://carrito.grupoacssi.com/venta/',
        },
        {
            imgSrc: img_t3,
            imgAlt: 'Descripción de la imagen 3',
            previewLinkTitle: 'Classic Sale',
            detailsLink: 'https://carrito.grupoacssi.com/demo1/',
        }
    ];
    const galleryItems2 = [
        {
            imgSrc: img_w1,
            imgAlt: 'Descripción de la imagen 1',
            previewLinkTitle: 'Creative Basic',
            detailsLink: 'https://webs.grupoacssi.com/creative_1/',
        },
        {
            imgSrc: img_w2,
            imgAlt: 'Descripción de la imagen 2',
            previewLinkTitle: 'Basic Profile',
            detailsLink: 'https://webs.grupoacssi.com/creative_2/',
        },
        {
            imgSrc: img_w3,
            imgAlt: 'Descripción de la imagen 3',
            previewLinkTitle: 'Basic Web Demo',
            detailsLink: 'https://webs.grupoacssi.com/creative_3/',
        },
        {
            imgSrc: img_w4,
            imgAlt: 'Descripción de la imagen 4',
            previewLinkTitle: 'Creative 4',
            detailsLink: 'https://webs.grupoacssi.com/creative_4/',
        },
    ];
    const advantages = [
        'Inversión mínima',
        'Soluciones sencillas',
        'Garantía y respaldo con experiencia',
        'Asistencia técnica más inteligente',
        'Asistencia remota',
        'Metodología ágil',
        'Compromiso y vocación ala tecnología'
    ];

    return (
        <>
            <Header></Header>


            <main id="main">
                <section id="home">
                    <Carousel />
                </section>
                
                <ParallaxSection image={img_11}>
                <section id="mision" className="about pt-5">
                    <div className="container">

                        <div className="row gy-4 justify-content-center" >
                            <div className="col-lg-6 content" data-aos="fade-right">

                                <div className="card w-100 content" >

                                    <div className="card-body">
                                        <h2>Misión</h2>
                                        <p className="fst-italic py-3">
                                            Nuestro principal compromiso es ofrecer asesoría tecnológica profesional de primer nivel que resuelvan y prevengan problemas en la infraestructura informática de nuestros clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 " data-aos="fade-left">
                                <div className="card w-100 content" >

                                    <div className="card-body">
                                        <h2>Visión</h2>
                                        <p className="fst-italic py-3">
                                            Grupo ACSSI tiene como visión ser una empresa de desarrollo de aplicaciones con tecnologías de vanguardia para aumentar y mejorar la productividad de nuestros clientes. Comprometidos, honrados y leales, así como una filosofía de trabajo eficaz basada en el desarrollo ágil para la entrega de resultados, son las características que engloban nuestros principales valores.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
                </ParallaxSection>
                <section id="services">
                    <div className="page-header d-flex align-items-center" data-aos="fade-left">
                        <div className="container position-relative">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <h2>Servicios</h2>
                                    <p>Contamos con varios servicios para brindar soporte a nuestros clientes que solucionen una necesidad.</p>

                                    <a className="cta-btn" href="#services">Más</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ParallaxSection image={img_sistema}>
                <ServicesSection
                    id="software-services"
                    sectionTitle="DESARROLLO DE SOFTWARE"
                    services={softwareServices}
                />
                </ParallaxSection>
                
                <ServicesSection
                    id="network-services"
                    sectionTitle="REDES"
                    services={networkServices}
                />
                <ServicesSection
                    id="maintenance-services"
                    sectionTitle="SOPORTE"
                    services={maintenanceServices}
                />
               
                <GallerySection title="DEMOS CARRITO DE COMPRAS" items={galleryItems1} />
                <GallerySection title="DEMOS PÁGINAS WEB" items={galleryItems2} />
                <About
                    imgSrc={index_img}
                    imgAlt="Descripción de la imagen"
                    advantages={advantages}
                />

            </main>
            <ParallaxSection image={img_contact}>
            <Contact />
            </ParallaxSection>
            
            <Footer />
            <a href="https://api.whatsapp.com/send?phone=5563678720&text=Hola, nececito mas información de sus servicios. En específico lo siguiente:!" className="float" target="_blank">
                <i className="material-symbols-outlined">chat</i>
            </a>
        </>
    );
};

export default Home;
