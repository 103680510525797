import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img_1 from '../assets/img/06.jpg';
import img_2 from '../assets/img/index2.jpg';

const ControlledCarousel: React.FC = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: any) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} translate='yes'>
            <Carousel.Item>
                <img className='d-block w-100 w-100-1' src={img_1}></img>
                <Carousel.Caption>
                    <h3 className='text-primary'><span>GRUPO</span>  ACSSI</h3>
                    <p className='lh-lg lh-sm text-primary'>ASESORIA INFORMATICA A EMPRESAS CON PERSONAL DE PRIMER NIVEL, CABLEADO ESTRUCTURADO, SOPORTE EN REDES.</p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 w-100-1' src={img_2}></img>
                <Carousel.Caption>
                    <h3><span>GRUPO</span>  ACSSI</h3>
                    <p>
                        SOPORTE PREVENTIVO Y CORRECTIVO, ASESORIA INFORMATICA A EMPRESAS CON PERSONAL DE PRIMER NIVEL.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default ControlledCarousel;